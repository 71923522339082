<template>
  <div>
    <b-card style="min-height: 750px;">
      <b-card-header>
        <i class="fa fa-list-ol"></i> Karar Destek Paketlerim
        <b-button variant="primary" size="md" style="padding: 3px;" class="pull-right" @click="f_addNewWisdomData('wdm24')">
          <i class="fa fa-plus"></i> yeni karar destek paketi oluştur
        </b-button>
      </b-card-header>
      <b-row>
        <b-col v-for="(wdmr24, wdm24_ind) in d_wdm24List" sm="12" lg="3">
          <b-card footer-class="px-3 py-2" style="margin: 10px; height: 100%; background: #d0e2e2;">
            <img src="@/icon/1430869.png" style="width: 5em;">
            <div class="h5 text-primary mb-0 mt-2">{{ wdmr24.label }} <small>({{ wdmr24.key.split('_')[1] }})</small></div>
            <div slot="footer">
              <b-link class="font-weight-bold font-xs btn-block text-muted" href="javascript:;" @click="f_goTowdm24(wdmr24.key)">Detaylar <i class="fa fa-angle-right float-right font-lg"></i></b-link>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" lg="3">
          <b-card footer-class="px-3 py-2" style="margin: 10px; height: 100%; background: #afe6af; cursor: pointer;" @click="f_addNewWisdomData('wdm24')">
            <img src="@/icon/1430869.png" style="width: 5em;">
            <div class="h5 text-primary mb-0 mt-2" style="font-size: 40px; text-align: center;">+</div>
            <div slot="footer">
              <b-link class="font-weight-bold font-xs btn-block text-muted" href="javascript:;" @click="f_addNewWisdomData('wdm24')">yeni <i class="fa fa-angle-right float-right font-lg"></i></b-link>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card style="background: black;" no-body>
      <web-bottom></web-bottom>
    </b-card>
    <modal v-if="d_wisdomData.show" @close="d_wisdomData.show = false" :width="'750'">
      <h3 slot="header">{{ d_wisdomData.option_data[d_wisdomData.data_type].name.label }}</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_wisdomData.wisdom_data" :data_type="d_wisdomData.data_type" :option_data="d_wisdomData.option_data"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWdm()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_wisdomData.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { ClsWdmrManage } from "@/services/public/wdmr_manage";
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import GlobalService from '@/services/global';
import {
  default as Modal
} from '@/components/widgets/Modal';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  default as router
} from '@/router';

export default {
  name: 'MyDecisionMakingPackages',
  computed: {
    ...mapGetters({})
  },
  props: {},
  components: {
    Modal,
    WisdomDataShow,
    WisdomDataModal
  },
  data () {
    return {
      d_wdm24List: [],
      d_selectedWdmrData: '',
      d_wisdomData: {
        'view_mode': 'table',
        'change': '0',
        'show': false,
        'wisdom_data': {},
        'data_type': '',
        'wisdom_op': '',
        'option_data': {}
      },
      d_wdm22Data: { 'id': '' }
    };
  },
  created: function () {},
  mounted: function () {
    // console.log(this.$route.query);
    if (this.$route.query && this.$route.query.wdm22) {
      this.d_wdm22Data.id = this.$route.query.wdm22;
    } else {
      this.d_wdm22Data = JSON.parse(localStorage.getItem('wdm22'));
    }
    let data = {
      'key_list': ['wdm_wisdom_wdm24_v2.0'],
      'bucket': 'option'
    };
    GlobalService.get_multiple_cb_documents(data)
      .then(resp => {
        if (resp.data.status === 'success') {
          for (let i in resp.data.result) {
            this.d_wisdomData.option_data[resp.data.result[i].parent] = resp.data.result[i];
          }
          this.f_getwdm24List();
        } else {
          console.log('error ', resp.data.message);
        }
      });

  },
  methods: {
    f_goTowdm24: function (wdm24_key) {
      let route_data = {
        'name': 'algorithmdevelopment',
        'path': '/rule-engine/algorithm',
        'query': {
          'dmp_id': wdm24_key.split('_')[1]
        }
      };
      this.$router.push(route_data);
    },
    f_addNewWisdomData: function (data_type) {
      ClsWdmrManage.prepare_new_wisdom_data(this.d_wisdomData, data_type)
      this.d_wisdomData.show = true;
    },
    f_getwdm24List: function () {
      this.d_wdm24List = [];
      let parent_wdm = {
        'label': '',
        'key': 'wdm22_' + this.d_wdm22Data.id,
        'bucket': 'wisdom',
        'type': 'wdm22'
      };
      let child_wdm = {
        'label': '',
        'key': 'wdm_wisdom_wdm24_v2.0',
        'bucket': 'wisdom',
        'type': 'wdm24'
      };
      let relation_type = 'keys';
      ClsWdmrManage.get_related_wdmr_list(parent_wdm, child_wdm, relation_type)
        .then(resp => {
          if (resp && resp.list) {
            this.d_wdm24List = resp.list;
          }
        }, resp => {
          console.log('error ', resp);
        });
    },
    f_editChildWdmr: function (data_type) {
      this.d_wisdomData.wisdom_op = "edit";
      this.d_wisdomData.data_type = data_type;
      this.d_wisdomData.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedWdmrData));
      this.d_wisdomData.show = true;
    },
    f_getWdmrData: function (wdmr_key) {
      // Examples: wdmr_key => wdm7_1, wdm7_2, ......
      let data = { 'key': wdmr_key, 'bucket': 'wisdom' };
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_selectedWdmrData = resp.data.result;
          } else {
            this.d_selectedWdmrData = '';
            console.log('errorr f_getWdmrData : ', resp.data.message);
          }
        });
    },
    wisdom_show_update: function () {
      if (this.d_wisdomData.change === "0") {
        this.d_wisdomData.change = "1";
      } else {
        this.d_wisdomData.change = "0";
      }
      this.$forceUpdate();
    },
    f_saveWdm: function () {
      let parent_wdm = '';
      let child_wdm = '';
      let relation_type = 'keys';
      if (this.d_wisdomData.data_type === 'wdm24') {
        parent_wdm = { 'label': '', 'key': 'wdm22_' + this.d_wdm22Data.id, 'bucket': 'wisdom', 'type': 'wdm22' };
        child_wdm = { 'label': '', 'key': 'wdm_wisdom_wdm24_v2', 'bucket': 'wisdom', 'type': 'wdm24' };
        relation_type = 'keys';
      }
      let permissionControl = {};
      // UNCOMMENT FOR PERMISSION
      permissionControl = {'value': 'decision-making-system_create-dmp', 'i_used_this_permission': 1};
      ClsWdmrManage.save_child_wdmr(this.d_wisdomData, parent_wdm, child_wdm, relation_type, permissionControl)
        .then(resp => {
          // console.log(resp);
          if (this.d_wisdomData.data_type === 'wdm24') {
            this.f_getwdm24List();
          }
          let modal_data = { 'type': 'alert', 'text': 'Successfully recorded', 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }, resp => {
          let modal_data = { 'type': 'alert', 'text': resp, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    }
  },
  watch: {}
};

</script>

<style type="text/css">
.table-row-directory {
  border-bottom: solid 1px #e6e6e6;
}

.table-row-directory:hover {
  background: #ddfffd;
}

</style>

